<template>
    <div class="directory" :class="[width ? 'width-'+width : '',styleType ? 'directory-'+styleType : '' ]">
        <div class="title">
            资源文件夹
            <div class="editBtn" v-if="canEdit" >
                <el-button type="text" icon="iconfont icon-delete" title="删除文件夹"
                    @click="handleDeleteDir" v-show="activeDirId" >
                </el-button>
                <el-button type="text" icon="iconfont icon-edit" title="重命名文件夹"
                    @click="handleRenameDir" v-show="activeDirId" >
                </el-button>
                <el-button type="text" icon="el-icon-folder-add" title="新建文件夹"
                    @click="handleSaveDir" >
                </el-button>
            </div>
        </div>
        <div class="tree-content">
            <!-- <el-tree v-if="treeReset" :data="dirList" :props="defaultProps" :load="loadNode" lazy node-key="id"
                :default-expanded-keys="['']" :expand-on-click-node="false" highlight-current
                icon-class="el-icon-folder-opened" @node-click="handleNodeClick">
                <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                </span>
            </el-tree> -->
            <el-tree v-if="treeReset"
                :data="dirTree" :props="defaultProps"
                node-key="id"
                :default-expanded-keys="['']"
                highlight-current
                :indent='20'
                icon-class="iconfont icon-folder leftIcon"
                @node-click="handleNodeClick">

                <span class="custom-tree-node" slot-scope="{ node }">
                    <span>{{ node.label }}</span><i class="rightIcon el-icon-arrow-right"></i>
                </span>
            </el-tree>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="folderVisible"
            width="420px"
            :before-close="initActive"
        >
            <div class="folder-visible">
                <div class="f-v-title">文件夹名称</div>
                <div class="f-v-content">
                    <el-input class="form-item"
                              v-model="activeDirLabel" placeholder="请输入文件夹名称">
                    </el-input>
                </div>
                <div class="f-v-title">权重</div>
                <div class="f-v-content">
                    <el-input class="form-item"
                              v-model.number="activeDirSort" placeholder="请输入权重">
                    </el-input>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="folderVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveDir">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';

export default {
    components: {},
    props: {
        canEdit:{
            type: Boolean,
            default: true,
        },
        width:{
            type: String,
            default: 'normal',
        },
        styleType:{
            type: String,
            default: 'small',
        }
    },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            dirList: [],
            activeDirId: '',
            activeDirLevel: '',
            activeDirLabel: '',
            activeDirSort: '',
            treeReset: true,
            dirTree: [],
            folderVisible: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        this.initDirTree();
    },
    mounted() {},
    methods: {
        handleSelect() {},
        handleClose() {},
        handleOpen() {},
        initDirTree() {
            Core.Api.ResourceDir.tree().then((res) => {
                let list = this.sortListAndChild(res.list)
                console.log('list', list);
                this.dirTree = [
                    { name: '资源文件夹', id: '', children: list }
                ];
            });
        },
        sortListAndChild(list) {
            list.sort((i, j) => j.sort - i.sort)
            list.forEach(item => {
                if (item.children && item.children.length) {
                    item.children = this.sortListAndChild(item.children)
                }
            })
            return list
        },
        getResourceDirList(dirId = '') {
            return Core.Api.ResourceDir.list(dirId);
        },
        saveDir() {
            let method = this.saveType === 1 ? 'rename' : 'save'
            Core.Api.ResourceDir[method](this.activeDirLabel, this.activeDirId, this.activeDirSort).then(
                (res) => {
                    this.initDirTree();
                    this.activeDirId = '';
                    this.$message.success('保存成功');
                }
            );
            this.initActive()
        },
        handleRenameDir() {  // 重命名文件夹
            this.folderVisible = true
            this.saveType = 1
        },
        handleSaveDir() { // 新建文件夹
            this.folderVisible = true
            this.saveType = 0
        },
        handleDeleteDir() { // 删除文件夹
            this.$confirm('此操作将删除该文件夹, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.ResourceDir.delete(this.activeDirId).then((res) => {
                    this.initDirTree();
                    this.activeDirId = '';
                    this.$message.success('删除成功');
                });
            });
        },
        handleNodeClick(data, node, currentNode) {
            this.activeDirId = data.id;
            this.activeDirLevel = data.level;
            this.activeDirLabel = data.name;
            this.activeDirSort = data.sort;
            const pathLabelArr = this.getPathLabelArr(node);
            this.$emit('handlePathLabel', pathLabelArr, this.activeDirId);
            this.$emit('handleNodeClick', this.activeDirId);
        },
        initActive() {
            this.folderVisible = false
            this.activeDirLabel = '';
            this.activeDirSort = '';
        },
        getPathLabelArr(node, pathLabelArr = []) {
            if (node.label) {
                pathLabelArr.unshift(node.label);
            }
            if (node.parent.label) {
                this.getPathLabelArr(node.parent, pathLabelArr);
            }
            return pathLabelArr;
        },
        async loadNode(node, resolve) {
            if (node.level === 0) {
                return resolve([{ name: '资源文件夹', id: '' }]);
            }
            if (node.level > 3) {
                return resolve([]);
            }
            const dirId = node.data.id || '';
            const { list } = await this.getResourceDirList(dirId);
            const data = list.length ? list : [];
            resolve(data);
        }
        // initDirTree() {
        //     this.treeReset = false;
        //     this.$nextTick(() => {
        //         this.treeReset = true;
        //     });
        // }
    }
};
</script>


<style lang="scss" scoped>
.directory{
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    box-sizing: border-box;
    background: #fff;
    border-radius: 2px;
    &.width-auto{ width: 100%;}
    &.width-normal{
        width: 400px;
        min-width: 200px;
    }
    .title {
        padding: 10px 15px;
        box-sizing: border-box;
        width: 100%;
        height: 57px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(63, 69, 73, 1);
        padding-bottom: 10px;
        @include flex(row, space-between, center);
        @include border-bottom-1px(#E1E7EC);
        .editBtn{
            .el-button{
                font-size: 16px;
                color: rgba(0,0,0,0.45);
                &:hover{
                    color: #1D517E;
                }
            }
        }
    }
    .tree-content {
        .custom-tree-node {
            flex: 1;
            @include flex(row, space-between, center);
            font-size: 14px;
            padding-right: 16px;
            font-weight: 500;
            color: #3f4549;
            .rightIcon{
                transition: transform .3s linear;
            }
        }
    }
}
.directory-small {
    height: 100%;
}
.directory-large {
    min-height: 700px;
    padding-bottom: 52px;
}
.folder-visible {
    .f-v-title {
        margin-bottom: 15px;
    }
    .f-v-content {
        margin-bottom: 15px;
    }
}
</style>

<style lang="scss">
.directory {
    .tree-content {
        .el-tree-node__content {
            padding-left: 20px;
            background-color: #fff;
            &:first-child {
                .el-tree-node__expand-icon {
                    margin-left: 20px;
                    color: #000;
                }
            }
        }
        .el-tree-node__children {
            background-color: rgba(246,248,250,1);
            .el-tree-node__content {
                background-color: transparent;
                .el-tree-node__expand-icon {
                    margin-left: 20px;
                    color: rgba(0,0,0,0.2);
                }
            }
        }
        span.el-tree-node__expand-icon {
            transform: rotate(0);
        }
        span.expanded ~ span.custom-tree-node .rightIcon {
            transform: rotate(90deg);
        }
        span.is-leaf ~ span.custom-tree-node .rightIcon {
            color: transparent;
        }
        .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
            background:rgba(246,235,236,1);
            border-right: 2px solid #1D517E;
            span {color: #1D517E;}
            // span.is-leaf {
            //     transform: rotate(90deg);
            // }
        }
    }
}
.directory-large {
    .tree-content{
        .el-tree-node__content{
            height: 52px;
        }
        .el-tree-node__children{
            .el-tree-node__content{
                height: 40px;
            }
        }
    }
}
.directory-small {
    .tree-content{
        .el-tree-node__content{
            height: 32px;
        }
        .el-tree-node__children{
            .el-tree-node__content{
                height: 28px;
            }
        }
    }
}
</style>
